import React from "react"
import Seo from "../components/site-metadata"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "../components/container"
import Sidebar from "../components/sidebar"
import Title from "../components/title"
import { injectIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"

const InfosUtiles = ({ intl }) => {
  const title = intl.formatMessage({ id: "infos-utiles.title" })
  const description = intl.formatMessage({ id: "infos-utiles.description" })
  return (
    <main>
      <Seo title={`${title} de la Docteure Debord`} description={description} />
      <Header />
      <Container classes={"md:flex"}>
        <section className="md:w-3/4">
          <Title title={title} />
          <FormattedHTMLMessage id="infos-utiles.content" />
          <p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1549.600931295909!2d1.582862765454316!3d45.63210208512358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f91f08d01fcb73%3A0xca1449780a409902!2s25+Rue+du+Champ+de+Foire%2C+87130+La+Croisille-sur-Briance!5e0!3m2!1sfr!2sfr!4v1508760880062"
              width="600"
              height="450"
              frameBorder="0"
              allowFullScreen="allowfullscreen"
              title="Google maps"
            ></iframe>
          </p>
        </section>
        <Sidebar classes={"md:w-1/4"} />
      </Container>
      <Footer />
    </main>
  )
}

export default injectIntl(InfosUtiles)
